import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import  ContentRenderer  from '../components/contentRenderer'
import { Icon } from '@iconify/react';
import styled from "styled-components"
import Seo from "../components/seo"

const FlexLinkBack = styled(Link)`
  display:inline-flex;
  gap:10px;
  align-items: center;
  transition: all .2s ease-in;
  &:before {
    display:none;
  }
  &:hover {
    gap:15px;
    svg path {
      color:var(--color-collectif);
    }
   

}
`;


const DatePage = props => {
 //console.log("PROPS DATE PAGE: ", props.data) 
  return (
    <Layout location={props.path}>
       <Seo meta={props.data.datoCmsDate.seoMetaTags} />
      <FlexLinkBack to="../" title="retour">
        <Icon icon ="bx:arrow-back" style={{ color: 'var(--color-primary)', fontSize:'30px' }}/>
        <span>Retour à l'agenda</span>
      </FlexLinkBack>
      <h1>{props.data.datoCmsDate.titre}</h1>
      
      <span className="date_news">
        <Icon icon = "akar-icons:calendar" style={{width:"24px"}} />
        {props.data.datoCmsDate.dateDebut} 
     
                {props.data.datoCmsDate.dateFin && " au " + props.data.datoCmsDate.dateFin}
        </span>
      <GatsbyImage image={props.data.datoCmsDate.image.gatsbyImageData} alt="" style={{marginBottom:"30px"}}/>

      <ContentRenderer content={props.data.datoCmsDate.contenu} /> 
    
    </Layout>
  )
}

export const fragmentDate = graphql`
  fragment DatesContent on DatoCmsDate {
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    titre
    slug
    dateDebut(locale:"fr", formatString: "ddd DD MMM YYYY")
    dateFin (locale:"fr", formatString: "ddd DD MMM YYYY")
    meta {
      publishedAt(locale:"fr", formatString: "ddd DD MMM YYYY")
    }
    originalId
    id
    image {
      gatsbyImageData(
        layout:FULL_WIDTH
        height:450
        imgixParams:  {fit: "crop", ar: "16/9", crop: "focalpoint", duotone:"4E2680,66C4D6"} 


      )
    }
    contenu {
      ... on DatoCmsArticle {
          ...Article
      }   
      ... on DatoCmsGrid {
          ...Grid
      }
              
    }  
  }
`

export const query = graphql` 
  query DatesQuery($pageIdJim: String) {
    datoCmsDate(originalId: { eq: $pageIdJim }) {
      ...DatesContent
  }
}
`

export default DatePage 